@charset "UTF-8";
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.sr-only:focus, .sr-only:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

.offscreen {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 990px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1280px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1280px) and (max-width: 1340px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 990px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1280px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 990px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 990px) and (max-width: 1279px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1280px) and (max-width: 1340px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 990px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1280px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.accordions.active {
  margin-bottom: 20px;
}
.accordions.active h2.accordion-title {
  position: relative;
  padding: 0;
  margin: 0;
}
.accordions.active h2.accordion-title button {
  font-size: 15px;
  line-height: 18px;
  font-size: 0.9375rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  position: relative;
  width: 100%;
  padding: 20px 82px 10px 0;
  margin: 0 0 10px 0;
  font-weight: 700;
  color: black;
  text-align: left;
  background: transparent;
  border: 0;
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title button span {
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 80px;
  height: calc(100% + 2px);
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title button span:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-rendering: optimizeLegibility;
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 18px;
  font-style: normal;
  color: black;
  text-align: center;
  text-decoration: inherit;
  content: "\f067";
  transform: translateY(-8px);
}
.accordions.active h2.accordion-title button:hover, .accordions.active h2.accordion-title button:focus {
  cursor: pointer;
}
.accordions.active h2.accordion-title button:hover, .accordions.active h2.accordion-title button:focus {
  color: blue;
  background: transparent;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
}
.accordions.active h2.accordion-title button:hover span, .accordions.active h2.accordion-title button:focus span {
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title.is-active button {
  color: blue;
  background: transparent;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
}
.accordions.active h2.accordion-title.is-active button span {
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title.is-active button span:after {
  content: "\f068";
}
.accordions.active h2.accordion-title.is-active button:focus {
  color: blue;
  background: transparent;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
}
.accordions.active h2.accordion-title.is-active button:focus span {
  border-bottom: 2px solid gray;
}
.accordions.active h2.accordion-title.is-active button:focus span:after {
  content: "\f068";
}
.accordions.active .accordion-content[aria-hidden=true] {
  display: none;
}
.accordions.active .accordion-content[aria-hidden=false] {
  display: block;
}
.accordions.active .accordion-content {
  padding: 10px;
}

.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  padding: 0;
  margin: 0;
  list-style: none !important;
}
@media (min-width: 990px) {
  .two-column,
.three-column,
.four-column,
.five-column,
.six-column {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
}
.two-column .col,
.three-column .col,
.four-column .col,
.five-column .col,
.six-column .col {
  width: 100%;
}

@media (min-width: 990px) {
  .two-column .col {
    width: calc(50% - (30px / 2));
    margin: 0 15px 15px 15px;
  }
  .two-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .two-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (min-width: 990px) {
  .three-column .col {
    width: calc((100% - 30px) / 2);
    margin: 0 15px 15px 15px;
  }
  .three-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .three-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .three-column .col {
    width: calc((100% - (30px * 2)) / 3);
    margin: 0 15px 15px 15px;
  }
  .three-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .three-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .three-column .col:nth-of-type(3n+1) {
    margin-left: 0;
  }
  .three-column .col:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media (min-width: 990px) {
  .four-column .col {
    width: calc((100% - 30px) / 2);
    margin: 0 15px 15px 15px;
  }
  .four-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .four-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .four-column .col {
    width: calc((100% - (30px * 3)) / 4);
    margin: 0 15px 15px 15px;
  }
  .four-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .four-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .four-column .col:nth-of-type(4n+1) {
    margin-left: 0;
  }
  .four-column .col:nth-of-type(4n) {
    margin-right: 0;
  }
}

@media (min-width: 990px) {
  .five-column .col {
    width: calc((100% - 30px) / 2);
    margin: 0 15px 15px 15px;
  }
  .five-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .five-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .five-column .col {
    width: calc((100% - (30px * 4)) / 5);
    margin: 0 15px 15px 15px;
  }
  .five-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .five-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .five-column .col:nth-of-type(5n+1) {
    margin-left: 0;
  }
  .five-column .col:nth-of-type(5n) {
    margin-right: 0;
  }
}

@media (min-width: 990px) {
  .six-column .col {
    width: calc((100% - 30px) / 2);
    margin: 0 15px 15px 15px;
  }
  .six-column .col:nth-of-type(2n+1) {
    margin-left: 0;
  }
  .six-column .col:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media (min-width: 1280px) {
  .six-column .col {
    width: calc((100% - (30px * 5)) / 6);
    margin: 0 15px 15px 15px;
  }
  .six-column .col:nth-of-type(2n+1) {
    margin-left: 15px;
  }
  .six-column .col:nth-of-type(2n) {
    margin-right: 15px;
  }
  .six-column .col:nth-of-type(6n+1) {
    margin-left: 0;
  }
  .six-column .col:nth-of-type(6n) {
    margin-right: 0;
  }
}

body.modal-open {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1071;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s;
}
.modal-overlay.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1072;
  display: none;
  width: 98vw;
  height: auto;
  max-height: calc(90vh - 40px);
  min-height: calc(90vh - 40px);
  overflow: visible;
  text-align: center;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 990px) {
  .modal {
    width: 96vw;
    height: auto;
    max-height: 90vh;
    min-height: auto;
    overflow-y: visible;
  }
}
@media only screen and (min-width: 1280px) {
  .modal {
    width: 60vw;
    height: auto;
    max-width: 500px;
    max-height: 90vh;
    min-height: auto;
    overflow: visible;
  }
  .modal.wide {
    width: 80vw;
    max-width: 80vw;
  }
}
@media only screen and (min-width: 1341px) {
  .modal {
    width: 50vw;
    height: auto;
    max-width: 640px;
    max-height: 90vh;
    min-height: auto;
    overflow: visible;
  }
  .modal.wide {
    width: 80vw;
    max-width: 80vw;
  }
}
.modal.show-modal {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s, opacity 0.25s 0s;
}
.modal .close-modal {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  background-color: lightgray;
  border-radius: 0.25rem;
}
.modal .close-modal:hover, .modal .close-modal:focus {
  background-color: darkgray;
}
.modal .modal-content {
  height: 100%;
  max-height: inherit;
  padding: 31px 20px 20px;
  overflow-y: auto;
}

.modal-inline-parent {
  position: relative !important;
  z-index: auto !important;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.flex-container {
  margin: 0 auto 10px auto;
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1280px) {
  .flex-container {
    margin-bottom: 20px;
  }
  .flex-container.alignleft {
    width: 40%;
    float: left;
    margin: 0 15px 10px 0;
  }
  .flex-container.alignright {
    width: 40%;
    float: right;
    margin: 0 0 10px 15px;
  }
  .flex-container.aligncenter {
    width: 40%;
    margin: 0 auto 10px auto;
  }
}

.flex-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.search-results {
  font-size: 14px;
  line-height: 22px;
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: normal;
}
.search-results .stats {
  margin: 30px 0;
}
.search-results .search-results-search {
  background: lightgray;
  overflow: hidden;
  padding: 0;
  margin-bottom: 30px;
}
.search-results .search-results-search input[type=search] {
  border: 0;
  padding: 0;
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 30px;
  padding: 0 8px;
  width: calc(100% - 118px);
  font-style: italic;
  float: left;
}
.search-results .search-results-search input[type=search]::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=search]:-moz-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=search]::-moz-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=search]:-ms-input-placeholder {
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  font-style: italic;
}
.search-results .search-results-search input[type=submit] {
  display: inline-block;
  border: 0;
  padding: 0 8px;
  font-size: 16px;
  line-height: 30px;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: normal;
  height: 30px;
  width: 114px;
  text-align: center;
  float: right;
  text-decoration: none;
}
.search-results .search-results-search input[type=submit]:hover, .search-results .search-results-search input[type=submit]:focus {
  text-decoration: none;
}
.search-results .results {
  position: relative;
  padding-top: 20px;
}
.search-results .results:before {
  content: "";
  background: gray;
  position: absolute;
  top: -16px;
  width: 100%;
  height: 1px;
}
.search-results .pagination {
  border-top: 1px solid gray;
}
.search-results .pagination > td {
  padding: 16px 0 0 0;
}
.search-results .pagination span,
.search-results .pagination a {
  background: lightgray;
  color: gray;
  display: inline-block;
  text-decoration: none;
  padding: 3px 6px;
  border: 1px solid gray;
  font-size: 13px;
  line-height: 13px;
  font-size: 0.8125rem;
  line-height: 0.8125rem;
  letter-spacing: normal;
}
.search-results .pagination a {
  color: blue;
}
.search-results .pagination a:visited {
  color: blue;
}
.search-results .pagination a:hover, .search-results .pagination a:focus {
  color: red;
}
.search-results th[scope=col] {
  display: none;
}
.search-results tr:nth-child(n+2):not(.pagination) > td {
  font-size: 16px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  padding-bottom: 20px;
}
.search-results tr:nth-child(n+2):not(.pagination) > td a {
  display: block;
  font-size: 18px;
  line-height: 21px;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: normal;
  letter-spacing: 0.0625em;
  font-weight: 400;
}

@media screen and (max-width: 1279px) {
  :not(.calendar-table) table.responsive-table {
    width: 100%;
  }
  :not(.calendar-table) table.responsive-table thead tr {
    display: none;
  }
  :not(.calendar-table) table.responsive-table td {
    display: block;
    clear: left;
  }
  :not(.calendar-table) table.responsive-table .cell-content {
    display: block;
  }
}

.tabs {
  position: relative;
  margin-bottom: 20px;
}
.tabs.scroll-left:before, .tabs.scroll-right:after {
  position: absolute;
  z-index: 99;
  width: 40px;
  height: 60px;
  content: "";
}
.tabs.scroll-left:before {
  top: 0;
  left: 0;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs.scroll-right:after {
  top: 0;
  right: 0;
  background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
}
.tabs .tab-con {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 2px;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: normal;
}
.tabs .tab-list[role=tablist] {
  display: inline-block;
}
.tabs .tab-list li {
  display: inline-block;
  margin: 0 3px 0 0;
  padding: 0;
  white-space: nowrap;
}
.tabs .tab-list li:last-child {
  margin: 0;
}
.tabs .tab-list li a {
  position: relative;
  display: block;
  padding: 12px 19px;
  background: gray;
  color: black;
  text-align: center;
  text-decoration: none;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a:focus {
  outline: 0;
  background: black;
  color: white;
}
.tabs .tab-list li a[aria-selected] {
  border: 0;
  background: gray;
  color: black;
  padding: 12px 19px;
}
.tabs .tab-list li a[aria-selected]:hover, .tabs .tab-list li a[aria-selected]:focus {
  background: gray;
  color: black;
}
.tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.tabs [role=tabpanel] .tab-title {
  display: none;
}

.accordions.active {
  overflow: hidden;
  margin-bottom: 0 !important;
}
.accordions.active h2.accordion-title.is-active button {
  color: #014174;
  border-bottom-color: #cb2128 !important;
  transition: all 0.5s ease-in-out;
}
.accordions.active h2.accordion-title.is-active button span {
  border-bottom-color: #cb2128 !important;
  transition: all 0.5s ease-in-out !important;
}
.accordions.active h2.accordion-title.is-active button span:after {
  transform: rotate(-90deg);
}
.accordions.active h2.accordion-title.is-active button:hover, .accordions.active h2.accordion-title.is-active button:focus {
  color: #111f90;
  border-bottom-color: #111f90;
}
.accordions.active h2.accordion-title.is-active button:hover span, .accordions.active h2.accordion-title.is-active button:focus span {
  border-bottom-color: #111f90;
}
.accordions.active h2.accordion-title.is-active button:hover span:after, .accordions.active h2.accordion-title.is-active button:focus span:after {
  color: #111f90;
}
.accordions.active h2.accordion-title button {
  padding-left: 1px;
  margin-left: 0;
  font-size: 18px;
  color: #cb2128 !important;
  border-bottom-color: #e9eaf5;
  transition: all 0.5s ease-in-out;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.accordions.active h2.accordion-title button span {
  right: 0;
  left: auto;
  width: 50px;
  border: 0 !important;
  transition: all 0.5s ease-in-out;
}
.accordions.active h2.accordion-title button span:after {
  left: 50%;
  width: 20px;
  height: 20px;
  background: transparent url(/themes/SSGA/assets/dist/images/accordion-arrow-mim.png) no-repeat center center;
  background-size: contain;
  content: "" !important;
  transform: translateX(-50%) rotate(90deg);
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out !important;
  transform-origin: 40% 60%;
}
.accordions.active h2.accordion-title button:hover, .accordions.active h2.accordion-title button:focus {
  color: #cb2128 !important;
}
.accordions.active h2.accordion-title button:hover span:after, .accordions.active h2.accordion-title button:focus span:after {
  color: #cb2128 !important;
}

.banner {
  position: relative;
  z-index: 1;
  width: 100%;
  height: calc(100vh - 150px);
  padding: 0 30px;
  margin-top: 0;
  background-color: #000;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 75%;
}
.banner .banner-slide {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 990px) {
  .banner .banner-slide {
    background-position: center top;
  }
  .banner .banner-slide:before {
    position: absolute;
    bottom: -18vw;
    left: -17vw;
    z-index: 1;
    width: 40vw;
    height: 30vw;
    background: linear-gradient(to bottom, rgba(255, 254, 255, 0.1), rgba(255, 255, 255, 0.25));
    content: "";
    transform: rotate(45deg);
  }
}
.banner .banner-slide:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 61vh;
  background: linear-gradient(to top, rgb(0, 0, 0) 40%, transparent);
  content: "";
}
@media screen and (min-width: 990px) {
  .banner .banner-slide:after {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 25%, transparent);
  }
}
@media screen and (min-width: 990px) {
  .banner {
    height: calc(100vh - 130px);
    min-height: 850px;
    padding: 0 75px;
    margin-top: -180px;
    overflow: hidden;
    background-size: cover;
  }
  .banner .arrow-indicator {
    position: absolute;
    right: 75px;
    bottom: 0;
    z-index: 5;
    width: 125px;
    height: 62px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.25);
    border-bottom: 0;
    border-radius: 70px 70px 0 0;
    transition: all 0.5s ease-in-out;
  }
  .banner .arrow-indicator:hover, .banner .arrow-indicator:focus {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.25);
  }
  .banner .arrow-indicator:hover .arrow-wing-left, .banner .arrow-indicator:focus .arrow-wing-left {
    left: 55%;
  }
  .banner .arrow-indicator:hover .arrow-wing-right, .banner .arrow-indicator:focus .arrow-wing-right {
    left: calc(53% + 0.125rem);
  }
  .banner .arrow-wing-left,
.banner .arrow-wing-right {
    position: absolute;
    bottom: 0.5rem;
    left: 52%;
    z-index: 3;
    display: block;
    width: 10px;
    height: 24px;
    background: #00b1e1;
    transform: translateX(-50%) rotate(225deg);
    transition: all 0.25s ease-in-out;
  }
  .banner .arrow-wing-right {
    top: 18px;
    left: calc(50% + 0.125rem);
    z-index: 2;
    background: #74bb1f;
    transform: translateX(-50%) rotate(135deg);
  }
}
.banner .banner-text {
  position: absolute;
  bottom: 100px;
  left: 50%;
  z-index: 5;
  width: calc(100% - 60px);
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);
}
@media screen and (min-width: 990px) {
  .banner .banner-text {
    bottom: 80px;
    left: auto;
    width: calc(100% - 35px);
    text-align: right;
    transform: none;
  }
}
@media screen and (min-width: 1440px) {
  .banner .banner-text {
    width: calc(100% - 150px);
  }
}
.banner h2 {
  position: relative;
  z-index: 5;
  width: 100%;
  margin: 0 0 20px 0;
  font-size: 42px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0.1rem;
  color: #fff;
}
@media screen and (min-width: 990px) {
  .banner h2 {
    margin-bottom: 10px;
    font-size: 60px;
    line-height: 60px;
    letter-spacing: 0.05rem;
  }
}
@media screen and (min-width: 1440px) {
  .banner h2 {
    margin-bottom: 20px;
    font-size: 80px;
    line-height: 80px;
  }
}
.banner h2 span {
  font-weight: lighter;
  color: #53a7dc;
}
.banner .banner-small-text {
  position: relative;
  z-index: 5;
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: lighter;
  line-height: 18px;
  letter-spacing: 0.2rem;
  color: #fff;
}
.banner .banner-small-text a {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  margin-top: 5px;
  font-size: 18px;
  color: #cb2128;
  border: 0;
  transition: all 0.5s ease-in-out;
}
.banner .banner-small-text a:hover, .banner .banner-small-text a:focus {
  text-decoration: underline;
}
@media screen and (min-width: 990px) {
  .banner .banner-small-text {
    font-size: 24px;
    line-height: 24px;
  }
}

@media screen and (min-width: 990px) {
  main#main {
    position: relative;
    z-index: 7;
  }
  .subpage main#main:after {
    position: absolute;
    top: -90px;
    right: 0;
    z-index: 0;
    display: block;
    width: 223px;
    height: 615px;
    content: "";
    background: none;
  }
}

.branding {
  position: relative;
  display: inline-block;
  width: calc(100% - 65px);
  height: 100%;
}
@media screen and (min-width: 990px) {
  .branding {
    width: auto;
  }
}
.branding * {
  height: 100%;
}
.branding a {
  border: 0;
}

.logo {
  display: block;
  width: 260px;
  height: 80px;
  background: url("/themes/SSGA/assets/dist/images/LOGO.png") 20px center no-repeat;
  background-size: auto calc(100% - 24px);
}
@media screen and (min-width: 990px) {
  .logo {
    width: 260px;
    height: 185px;
    padding: 35px 40px;
    background-color: transparent;
    background-image: url("/themes/SSGA/assets/dist/images/LOGO.png");
    background-position: center center;
    background-size: calc(100% - 80px) auto;
    box-sizing: content-box;
  }
}
.logo:after {
  position: absolute;
  top: 0;
  left: -1060px;
  z-index: -1;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  border-top: 530px solid #111f90;
  border-right: 530px solid transparent;
  border-bottom: 0 solid;
  border-left: 1060px solid transparent;
}
@media screen and (min-width: 990px) {
  .logo:after {
    top: -15px;
    content: "";
  }
}
@media screen and (min-width: 1280px) {
  .logo:after {
    top: 0;
  }
}
.logo:before {
  position: absolute;
  bottom: -502px;
  left: -53px;
  z-index: 0;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  border-top: 215px solid transparent;
  border-right: 0;
  border-bottom: 215px solid transparent;
  border-left: 215px solid #cb2128;
}
@media screen and (min-width: 990px) {
  .logo:before {
    bottom: -35.7rem;
    content: "";
  }
}
@media screen and (min-width: 1280px) {
  .logo:before {
    bottom: -422px;
  }
}
.stuck .logo {
  position: relative;
  z-index: 5;
  width: 280px;
  height: 60px;
  padding: 0;
  margin: 15px 0;
  background-image: url("/themes/SSGA/assets/dist/images/LOGO.png");
  background-position: 20px center;
  background-size: contain;
  box-sizing: border-box;
}
@media all and (max-width: 340px) {
  .stuck .logo {
    width: 17rem;
    background-position: 0.2rem center;
  }
}
.stuck .logo:after, .stuck .logo:before {
  content: none;
}

footer.primary a {
  color: #cb2128;
}

footer.primary ul.social-nav li a {
  color: #cb2128;
}

.breadcrumbs {
  background: #6d6e71;
}

.bc-container {
  display: flex;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}
@media screen and (min-width: 990px) {
  .bc-container {
    width: calc(100% - 350px);
    padding: 20px 0;
    margin-left: 160px;
  }
}
@media screen and (min-width: 1210px) {
  .bc-container {
    margin-left: auto;
  }
}

.breadcrumb {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - (10% + 25px));
  padding: 0;
  margin: 0;
  text-shadow: 1px 0 5px rgba(0, 0, 0, 0.35);
  vertical-align: middle;
  list-style: none;
}
@media screen and (min-width: 990px) {
  .breadcrumb {
    max-width: 1200px;
  }
}
.breadcrumb li {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  position: relative;
  display: none;
  margin-right: 30px;
  font-size: 16px;
  line-height: 41px;
  color: #fff;
  white-space: nowrap;
}
@media screen and (min-width: 990px) {
  .breadcrumb li {
    display: block;
    line-height: 19px;
  }
}
.breadcrumb li:after {
  position: absolute;
  bottom: 12px;
  left: -18px;
  z-index: 1;
  width: 11px;
  height: 16px;
  background: transparent url(/themes/SSGA/assets/dist/images/accordion-arrow-mim.png) no-repeat center center;
  background-size: contain;
}
.bc-show-all .breadcrumb li:after {
  content: "";
}
@media screen and (min-width: 990px) {
  .breadcrumb li:after {
    bottom: 0.1rem;
    content: "";
  }
}
.breadcrumb li:last-child, .bc-show-all .breadcrumb li {
  display: block;
}
.breadcrumb li a {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 0;
  color: #fff;
  text-decoration: none;
  border: 0;
  transition: all 0.5s ease-in-out;
}
.breadcrumb li a:visited {
  color: #fff;
}
.breadcrumb li a:hover, .breadcrumb li a:focus {
  color: #fff;
  text-decoration: underline;
}

a.bc-home {
  position: relative;
  z-index: 5;
  display: inline-block;
  width: 100px;
  min-width: 16px;
  padding-left: 65px;
  margin-right: 5px;
  margin-left: -65px;
  font-weight: normal;
  line-height: 41px;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
  background: transparent url(/themes/SSGA/assets/dist/images/icn-home.png) no-repeat calc(100% - 25px) center;
  background-color: #545456;
  background-size: 16px;
  border: 0;
  transition: all 0.5s ease-in-out;
  clip-path: polygon(calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0 100%, 0% 0%);
}
a.bc-home:visited {
  color: #fff;
}
a.bc-home:hover, a.bc-home:focus {
  color: #fff;
  text-decoration: underline;
}
a.bc-home > span {
  display: none;
}
@media screen and (min-width: 990px) {
  a.bc-home {
    width: auto;
    padding-right: 5px;
    margin-right: 25px;
    background: transparent;
  }
  a.bc-home > span {
    display: inline;
  }
}

.bc-expand {
  padding-right: 20px;
  padding-bottom: 10px;
  padding-left: 25px;
  margin-right: 10px;
  margin-left: -25px;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  color: #fff;
  background: #5d5e60;
  border: 0;
  transition: all 0.5s ease-in-out;
  clip-path: polygon(calc(100% - 1.25rem) 0%, 100% 50%, calc(100% - 1.25rem) 100%, 0 100%, 0% 0%);
}
.bc-expand:hover, .bc-expand:focus {
  cursor: pointer;
  background: #545456;
}
.bc-show-all .bc-expand {
  display: none;
}
@media screen and (min-width: 990px) {
  .bc-expand {
    display: none;
  }
}

.main-content ul.call-out-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
}
.main-content ul.call-out-nav li {
  padding: 30px;
  margin: 10px 0;
  text-align: center;
  background: #111f90;
  border: 2px solid #111f90;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
.main-content ul.call-out-nav li:hover, .main-content ul.call-out-nav li:focus, .main-content ul.call-out-nav li:focus-within {
  background: #fff;
}
.main-content ul.call-out-nav li:hover a, .main-content ul.call-out-nav li:focus a, .main-content ul.call-out-nav li:focus-within a {
  color: #111f90;
}
.main-content ul.call-out-nav li:before {
  content: none;
}
.main-content ul.call-out-nav li.half {
  width: 100%;
}
@media screen and (min-width: 990px) {
  .main-content ul.call-out-nav li.half {
    width: calc(50% - 10px);
  }
}
.main-content ul.call-out-nav li.third {
  width: 100%;
}
@media screen and (min-width: 990px) {
  .main-content ul.call-out-nav li.third {
    width: calc(33% - 10px);
  }
}
.main-content ul.call-out-nav li a {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  font-size: 30px;
  font-weight: 900;
  line-height: 1.25em;
  color: #fff;
  text-align: center;
  border: 0;
  transition: all 0.5s ease-in-out;
}
.main-content ul.call-out-nav li a > div {
  width: 100%;
  margin: 25px 0 0;
}
.main-content ul.call-out-nav li a:before {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 1;
  width: 80px;
  height: 26px;
  background: transparent url(/themes/SSGA/assets/dist/images/callout-arrow.png) no-repeat center center;
  background-size: contain;
  content: "";
  transform: translateX(-50%);
}
.main-content ul.call-out-nav li a img {
  height: 70px;
  margin: 0 auto;
  clip-path: none;
}

.main-content ul.company-directory {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.main-content ul.company-directory li {
  width: 100%;
  margin: 0;
  margin-bottom: 50px;
}
@media screen and (min-width: 640px) {
  .main-content ul.company-directory li {
    width: calc(50% - 20px);
    margin-right: 40px;
  }
  .main-content ul.company-directory li:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 990px) {
  .main-content ul.company-directory li {
    width: calc(33% - 25px);
  }
  .main-content ul.company-directory li:nth-child(2n) {
    margin-right: 40px;
  }
  .main-content ul.company-directory li:nth-child(3n) {
    margin-right: 0;
  }
}
.main-content ul.company-directory li .arrow-indicator {
  position: absolute;
  right: -36px;
  bottom: -27px;
  z-index: 5;
  display: none;
  width: 125px;
  height: 62px;
  background: transparent;
  transform: rotate(-90deg);
}
.main-content ul.company-directory li .arrow-wing-left,
.main-content ul.company-directory li .arrow-wing-right {
  position: absolute;
  bottom: 17px;
  left: 55%;
  z-index: 3;
  display: block;
  width: 15px;
  height: 43px;
  background: #111f90;
  transform: translateX(-50%) rotate(45deg);
}
.main-content ul.company-directory li .arrow-wing-right {
  left: calc(45% - 7px);
  z-index: 2;
  background: #cb2128;
  transform: translateX(-50%) rotate(-45deg);
}
.main-content ul.company-directory li > a {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border: 0;
  transition: all 0.5s ease-in-out;
}
.main-content ul.company-directory li > a:hover .details, .main-content ul.company-directory li > a:focus .details {
  background-color: #0a1151;
  border-top-color: #070d3d;
}
.main-content ul.company-directory li > a:hover .arrow-indicator, .main-content ul.company-directory li > a:focus .arrow-indicator {
  display: block;
}
.main-content ul.company-directory li:before {
  content: none;
}
.main-content ul.company-directory .photo {
  width: 100%;
}
.main-content ul.company-directory .photo img {
  min-width: 100%;
  min-height: 100%;
  clip-path: none;
}
.main-content ul.company-directory .details {
  width: 100%;
  padding: 20px 30px 30px;
  color: #fff;
  background: #111f90;
  border-top: 5px solid #0d176c;
  --notchSize: 35px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
.main-content ul.company-directory .details h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
}
.main-content ul.company-directory .details .title {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
@media screen and (min-width: 990px) {
  .main-content ul.company-directory.four li {
    width: calc(50% - 20px);
    margin-right: 20px;
  }
  .main-content ul.company-directory.four li:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 1280px) {
  .main-content ul.company-directory.four li {
    width: calc(25% - 25px);
    margin-right: 25px;
  }
  .main-content ul.company-directory.four li:nth-child(2n) {
    margin-right: 25px;
  }
  .main-content ul.company-directory.four li:nth-child(4n) {
    margin-right: 0;
  }
}
.main-content ul.company-directory.four li .photo {
  position: relative;
}
.main-content ul.company-directory.four li .photo:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, transparent 40%, rgb(0, 0, 0));
}
.main-content ul.company-directory.four li .photo .personal-info {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 100;
}
.main-content ul.company-directory.four li .photo .personal-info h3 {
  color: #ffffff;
  margin-bottom: 0;
}
.main-content ul.company-directory.four li .photo .personal-info .title {
  color: #cb2128;
  text-transform: uppercase;
  font-weight: 700;
}
.main-content ul.company-directory.four li .photo .personal-info .company {
  color: #111f90;
  font-family: source-sans-pro, sans-serif;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
}
.main-content ul.company-directory.four li .details {
  border-top: none;
}
.main-content ul.company-directory.four li .details .bio {
  display: none;
}
.main-content ul.company-directory.four li .details .view-bio {
  position: relative;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  background: none;
  border: none;
  border-bottom: 3px solid #08569f;
  color: #fff;
  padding: 20px 0 20px 30px;
  cursor: pointer;
}
.main-content ul.company-directory.four li .details .view-bio:before {
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent url(/themes/SSGA/assets/dist/images/icon-plus.svg) no-repeat center center;
  background-size: contain;
}
.main-content ul.company-directory.four li .details .view-bio.is-active:before {
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent url(/themes/SSGA/assets/dist/images/icon-minus.svg) no-repeat center center;
  background-size: contain;
}
.main-content ul.company-directory.four li .details h4,
.main-content ul.company-directory.four li .details li {
  color: #ffffff;
}
.main-content ul.company-directory.four li .details h4 {
  margin-top: 20px;
  margin-bottom: 2px;
}
.main-content ul.company-directory.four li .details ul {
  margin-top: 0;
}
.main-content ul.company-directory.four li .details li {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
}
.main-content ul.company-directory.four li .details li:before {
  position: absolute;
  top: -1px;
  left: -16px;
  display: inline-block;
  font-size: 24px;
  font-size: 1.5rem;
  color: #cb2128;
  content: "•";
}
.main-content ul.company-directory.four li .details .social-links ul {
  padding-left: 0;
  padding-right: 0;
}
.main-content ul.company-directory.four li .details .social-links li {
  display: inline-block;
  width: inherit;
  margin-right: 5px;
}
.main-content ul.company-directory.four li .details .social-links li.hide {
  display: none;
}
.main-content ul.company-directory.four li .details .social-links li:before {
  display: none;
}
.main-content ul.company-directory.four li .details .social-links li a {
  position: relative;
  color: #ffffff;
  width: 35px;
  height: 35px;
  background-color: #cb2128;
  border-radius: 50px;
  font-size: 16px;
  font-weight: normal;
}
.main-content ul.company-directory.four li .details .social-links li a:hover {
  background-color: #83c736;
}
.main-content ul.company-directory.four li .details .social-links li a:before {
  content: "\f0e0";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  top: 8px;
  left: 9.5px;
  font-weight: 900;
}
.main-content ul.company-directory.four li .details .social-links li a.facebook:before, .main-content ul.company-directory.four li .details .social-links li a.twitter:before, .main-content ul.company-directory.four li .details .social-links li a.linkedin:before, .main-content ul.company-directory.four li .details .social-links li a.instagram:before {
  font-family: "Font Awesome 5 Brands";
  font-weight: normal;
}
.main-content ul.company-directory.four li .details .social-links li a.facebook {
  background-color: #3b5998;
}
.main-content ul.company-directory.four li .details .social-links li a.facebook:before {
  content: "\f39e";
  top: 7px;
  left: 13px;
}
.main-content ul.company-directory.four li .details .social-links li a.facebook:hover {
  background-color: #43609e;
}
.main-content ul.company-directory.four li .details .social-links li a.twitter {
  background-color: #111f90;
}
.main-content ul.company-directory.four li .details .social-links li a.twitter:before {
  content: "\f099";
  top: 8px;
  left: 10px;
}
.main-content ul.company-directory.four li .details .social-links li a.twitter:hover {
  background-color: #24bce6;
}
.main-content ul.company-directory.four li .details .social-links li a.linkedin {
  background-color: #0077b5;
}
.main-content ul.company-directory.four li .details .social-links li a.linkedin:before {
  content: "\f0e1";
  top: 7px;
  left: 11px;
}
.main-content ul.company-directory.four li .details .social-links li a.linkedin:hover {
  background-color: #1f85bd;
}
.main-content ul.company-directory.four li .details .social-links li a.instagram {
  background-color: #262626;
  font-size: 19px;
}
.main-content ul.company-directory.four li .details .social-links li a.instagram:before {
  content: "\f16d";
  top: 8px;
  left: 9px;
}
.main-content ul.company-directory.four li .details .social-links li a.instagram:hover {
  background-color: #353535;
}

.employee-profile:after {
  display: table;
  clear: both;
  content: "";
}
.employee-profile h2 {
  padding-bottom: 5px;
  margin: 0;
  font-size: 30px;
}
.employee-profile div.title {
  padding-bottom: 10px;
  margin-bottom: 30px;
  color: #262626;
  text-transform: uppercase;
  border-bottom: 2px solid #dcdeee;
}
.employee-profile .details img {
  margin-bottom: 40px;
  vertical-align: top;
  --notchSize: 40px;
}
@media screen and (min-width: 990px) {
  .employee-profile .details img {
    float: left;
  }
}
@media screen and (min-width: 990px) {
  .employee-profile .details > div {
    float: right;
    width: calc(100% - 320px);
  }
}
.employee-profile .details h3 {
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  color: #6d6e71;
}
.employee-profile .details p {
  margin-top: 5px;
}
.employee-profile .contact a {
  position: relative;
  display: inline-block;
  padding: 20px 25px 20px 70px;
  margin-right: 20px;
  overflow: hidden;
  font-size: 18px;
  color: #262626;
  background: #e7e8f4;
  border: 0;
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
}
.employee-profile .contact a:hover, .employee-profile .contact a:focus {
  color: #fff;
}
.employee-profile .contact a:hover:after, .employee-profile .contact a:focus:after {
  left: -5px;
  width: 500px;
  height: 500px;
}
.employee-profile .contact a:before {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  display: block;
  width: 50px;
  height: 50px;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 50px;
  color: #111f90;
  text-align: center;
  background: #111f90;
  border-radius: 50px;
  content: "\f00c";
}
.employee-profile .contact a.phone-contact:before {
  content: "\f095";
}
.employee-profile .contact a.email-contact:before {
  content: "\f0e0";
}
.employee-profile .contact a:after {
  position: absolute;
  top: 50%;
  left: 5px;
  z-index: 0;
  display: block;
  width: 40px;
  height: 40px;
  font-family: "Font Awesome 5 Free";
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 50px;
  text-align: center;
  background: #111f90;
  border-radius: 50px;
  content: "";
  transform: translateY(-50%);
  transition: 1s all ease-out;
}
.employee-profile .contact a span {
  position: relative;
  z-index: 2;
}

.elevator {
  position: relative;
  left: 50%;
  display: flex;
  width: 100vw;
  padding: 120px 20px;
  margin: 80px 0;
  overflow: hidden;
  transform: translateX(-50%);
}
@media screen and (min-width: 990px) {
  .elevator {
    padding: 130px 370px;
  }
}
.elevator:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 0;
  height: 0;
  background: transparent;
  border-top: 160px solid #111f90;
  border-right: 160px solid transparent;
  border-bottom: 0;
  border-left: 0 solid transparent;
  content: "";
}
.elevator:after {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 0;
  height: 0;
  background: transparent;
  border-top: 0;
  border-right: 0 solid transparent;
  border-bottom: 160px solid #cb2128;
  border-left: 160px solid transparent;
  content: "";
}
.elevator .content {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}
.elevator .content h2 {
  margin-top: 0;
  color: #fff;
}
.elevator .content .btn.btn-hollow {
  padding: 0 40px;
  color: #fff;
  border-color: #fff;
  transition: all 0.5s ease-in-out;
}
.elevator .content .btn.btn-hollow:hover, .elevator .content .btn.btn-hollow:focus {
  color: #262626;
  background: #fff;
}
.elevator img.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  clip-path: none;
  object-fit: cover;
}

.events-list-view .events-category-name,
.events-list-view .event-month {
  display: none;
}

article.event {
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 2px solid #dcdeee;
}
article.event:last-of-type {
  margin-bottom: 0;
  border-bottom: 0;
}
article.event:after {
  display: table;
  clear: both;
  content: "";
}
@media screen and (min-width: 990px) {
  article.event > * {
    float: right;
    width: calc(100% - 280px);
  }
}
article.event h2.event-title {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 30px;
  color: #111f90;
}
article.event h2.event-title a {
  line-height: 1em;
  border: 0;
}
article.event .event-date {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #262626;
}
article.event a.event-read-more {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  line-height: 40px;
  color: #111f90;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  border: 2px solid #111f90;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 990px) {
  article.event a.event-read-more {
    width: auto;
  }
}
article.event a.event-read-more:hover, article.event a.event-read-more:focus {
  color: #fff;
  background: #111f90;
}
article.event .event-image {
  position: relative;
  width: 100%;
  height: 185px;
  margin-bottom: 20px;
  overflow: hidden;
  --notchSize: 35px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
@media screen and (min-width: 990px) {
  article.event .event-image {
    float: left;
    width: 250px;
    margin-right: 30px;
  }
}
article.event .event-image img {
  position: absolute;
  left: 50%;
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%);
}

.event-details {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  border-bottom: 2px solid #dcdeee;
}
.event-details > * {
  width: 100%;
}
.event-details .event-details-title {
  order: 1;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 30px;
}
.event-details .event-details-date {
  position: relative;
  order: 2;
  padding-bottom: 20px;
  padding-left: 40px;
  font-size: 20px;
  color: #262626;
  border-bottom: 2px solid #dcdeee;
}
.event-details .event-details-date:before {
  position: absolute;
  top: -2px;
  left: 0;
  width: 30px;
  height: 26px;
  background: transparent url("/themes/SSGA/assets/dist/images/icn-calendar.png") no-repeat center center;
  background-size: contain;
  content: "";
}
.event-details .event-details-image {
  order: 3;
  width: 100%;
  height: auto;
  margin: 20px 0;
}
.event-details .event-details-image img {
  width: 100%;
  --notchSize: 40px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
.event-details .event-details-copy {
  order: 4;
}
.event-details .event-details-location {
  display: none;
  order: 5;
}
.event-details .ical {
  order: 6;
}
.event-details .ical a {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  position: relative;
  width: 100%;
  padding: 0 20px 0 50px;
  font-size: 16px;
  line-height: 40px;
  color: #111f90;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  border: 2px solid #111f90;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 990px) {
  .event-details .ical a {
    width: auto;
  }
}
.event-details .ical a:before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 40px;
  height: 100%;
  background: #111f90 url("/themes/SSGA/assets/dist/images/icn-calendar-add.png") no-repeat center center;
  background-size: 25px auto;
  content: "";
}
.event-details .ical a:hover, .event-details .ical a:focus {
  color: #fff;
  background: #111f90;
}
.event-details .returnlink {
  display: none;
}

.content-form .form-builder-section section:after {
  display: table;
  clear: both;
  content: "";
}
.content-form .form-builder-section label {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 18px;
  color: #262626;
}
.content-form .form-builder-section input[type=text],
.content-form .form-builder-section input[type=password],
.content-form .form-builder-section input[type=date],
.content-form .form-builder-section input[type=datetime],
.content-form .form-builder-section input[type=datetime-local],
.content-form .form-builder-section input[type=month],
.content-form .form-builder-section input[type=week],
.content-form .form-builder-section input[type=email],
.content-form .form-builder-section input[type=number],
.content-form .form-builder-section input[type=search],
.content-form .form-builder-section input[type=tel],
.content-form .form-builder-section input[type=time],
.content-form .form-builder-section input[type=url],
.content-form .form-builder-section input[type=file],
.content-form .form-builder-section textarea,
.content-form .form-builder-section select {
  padding: 10px 20px;
  border: 2px solid #dcdeee;
}
.content-form .form-builder-section select {
  cursor: pointer;
  background: transparent url("/themes/SSGA/assets/dist/images/icn-select-arrow.png") no-repeat calc(100% - 20px) center;
}
.content-form .form-builder-section .radio-list {
  padding: 0;
  margin: 0 0 20px 0;
}
.content-form .form-builder-section .radio-list ul {
  padding: 0 0 0 15px;
  margin: 0;
}
.content-form .form-builder-section .radio-list ul li {
  padding: 0;
  margin: 0;
}
.content-form .form-builder-section .radio-list input[type=radio] {
  position: relative;
}
.content-form .form-builder-section .radio-list input[type=radio]:checked:before {
  top: 50%;
  left: 50%;
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  font-weight: 900;
  line-height: 10px;
  color: #111f90;
  content: "\f111";
  transform: translateY(-50%) translateX(-50%);
}
.content-form .form-builder-section input[type=checkbox] {
  position: relative;
}
.content-form .form-builder-section input[type=checkbox]:checked:before {
  color: #111f90;
}
.content-form a[role*=tooltip] {
  border-bottom: none;
}
.content-form .tooltip {
  display: block;
  margin-bottom: 10px;
}
@media screen and (min-width: 990px) {
  .content-form .tooltip {
    position: relative;
    top: 32px;
    left: -40px;
    z-index: 1000;
    display: initial;
    padding: 3px 7px;
    margin-left: 15px;
    color: #fff;
    background-color: #111f90;
  }
}
@media screen and (min-width: 990px) {
  .content-form .tooltip .arrow {
    position: absolute;
    top: -9px;
    left: 9px;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-bottom: 9px solid #111f90;
    border-left: 7px solid transparent;
  }
}
.content-form div[id*=pnlResults] {
  padding: 10px;
  margin: 10px 15px 20px;
  font-weight: bold;
  color: #000;
  background-color: rgba(104, 166, 32, 0.25);
}
.content-form div[id*=pnlTotals] {
  margin: 20px 0;
  font-size: 20px;
  font-style: italic;
}

.form-builder-submit-controls input[type=submit],
.form-builder-submit-controls a.btn.btn-primary {
  color: #fff;
  text-transform: uppercase;
  background: #111f90;
  border: 1px solid #111f90;
  transition: all 0.5s ease-in-out;
}
.form-builder-submit-controls input[type=submit]:hover, .form-builder-submit-controls input[type=submit]:focus,
.form-builder-submit-controls a.btn.btn-primary:hover,
.form-builder-submit-controls a.btn.btn-primary:focus {
  background: #262626;
}
.form-builder-submit-controls a.form-builder-section-next {
  color: #111f90 !important;
}

.pager {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #cfd2e9;
}
.pager .pager-btn {
  color: #111f90;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}
.pager .pager-btn:hover, .pager .pager-btn:focus {
  color: #262626;
}
.pager .pagers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}
.pager .pagers .pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
}
.pager .pagers .pagination li:before {
  content: none;
}
.pager .pagers .pager-btn {
  padding: 10px 0;
  font-weight: bold;
}
.pager .pagers a {
  font-weight: normal;
  border: 0;
}
.pager .pagers a.active {
  font-weight: bold;
}

.alert {
  padding: 10px;
  margin: 0 0 20px 0;
  font-weight: bold;
  color: #000;
  background-color: rgba(104, 166, 32, 0.25);
}
.alert.danger {
  background-color: #fcc;
}
.alert ul {
  padding: 0;
  margin: 0;
}
.alert ul li {
  margin: 0;
}
.alert ul li:before {
  content: none;
}

* {
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
}

*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  position: relative;
  margin: 0;
  overflow-x: hidden;
  font-size: 100%;
  background: white;
  -webkit-overflow-scrolling: touch;
}

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

a {
  color: blue;
  transition: all 0.5s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:visited {
  color: blue;
}
a:hover, a:focus {
  color: purple;
  text-decoration: underline;
}

input {
  appearance: none;
}

.element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
}

.clear {
  zoom: 1;
}
.clear:before, .clear:after {
  display: table;
  content: "";
}
.clear:after {
  clear: both;
}

iframe {
  position: relative;
  background: #f2f2f2;
  border: 2px solid #dedede;
}

.m-bot__50 {
  margin-bottom: 50px;
}

h2 {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  font-size: 30px;
  color: #262626;
  text-transform: uppercase;
}
h2.arrowed {
  position: relative;
  width: 100%;
  padding: 0 calc(50% - 114px);
  text-align: center;
}
h2.arrowed:before, h2.arrowed:after {
  position: absolute;
  top: 50%;
  left: 0;
  width: calc(50% - 104px);
  height: 100%;
  height: 30px;
  background: transparent url(/themes/ssga/assets/dist/images/arrow-pattern.png);
  background-position: left center;
  background-repeat: repeat-x;
  background-size: 29px 30px;
  content: "";
  transform: translateY(-50%);
  clip-path: polygon(calc(100% - 24px) 0%, calc(100% - 10px) 50%, calc(100% - 24px) 100%, 0 100%, 0% 0%);
}
h2.arrowed:after {
  right: 0;
  left: auto;
  transform: translateY(-50%) rotate(180deg);
}
h2.arrowed.blue:before, h2.arrowed.blue:after {
  background-image: url("/themes/SSGA/assets/dist/images/arrow-pattern-blue.png");
}

@media screen and (min-width: 990px) {
  .map-actions {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1321px) {
  .map-actions {
    flex-wrap: nowrap;
    height: 39rem;
  }
}
.map-actions .actions,
.map-actions .map {
  width: 100%;
}
@media screen and (min-width: 1321px) {
  .map-actions .actions,
.map-actions .map {
    width: 50%;
  }
}
@media screen and (min-width: 990px) and (max-width: 1320px) {
  .map-actions .actions {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
  }
}
@media screen and (min-width: 1321px) {
  .map-actions .actions {
    display: block;
    width: 50%;
  }
}
.map-actions .map {
  display: none;
}
@media screen and (min-width: 990px) {
  .map-actions .map {
    display: flex;
    height: 39rem;
  }
  .map-actions .map p {
    width: 100%;
    margin: 0;
  }
  .map-actions .map .flex-video {
    position: relative;
    width: 100%;
    padding: 0 !important;
  }
  .map-actions .map iframe {
    position: absolute;
    left: 50%;
    width: 1260px;
    height: 630px;
    border: 0;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 1321px) {
  .map-actions .map iframe {
    height: 630px;
  }
}

.sites-buildings,
.regional-overview {
  width: 100%;
  padding: 45px 20px 60px;
  text-align: center;
  background: #cb2128;
}
@media screen and (min-width: 990px) and (max-width: 1320px) {
  .sites-buildings,
.regional-overview {
    width: 50%;
  }
}
@media screen and (min-width: 1321px) {
  .sites-buildings,
.regional-overview {
    height: 50%;
    padding: 60px 0 60px 50px;
    text-align: left;
  }
}
.sites-buildings h2,
.regional-overview h2 {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  width: 100%;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  text-shadow: 1px 0 3px rgba(0, 8, 51, 0.6);
  text-transform: uppercase;
}
@media screen and (min-width: 990px) {
  .sites-buildings h2,
.regional-overview h2 {
    margin-top: 0;
  }
}
.sites-buildings p,
.regional-overview p {
  margin-top: 5px;
  font-size: 18px;
  line-height: 24px;
  color: #262626;
}
.sites-buildings .icon,
.regional-overview .icon {
  width: 121px;
  height: 121px;
  margin: 0 auto 30px;
}
@media screen and (min-width: 990px) {
  .sites-buildings .icon,
.regional-overview .icon {
    float: right;
    width: 100px;
    height: 145px;
    margin: 6px 80px auto 40px;
  }
}
.sites-buildings .icon img,
.regional-overview .icon img {
  display: block;
  width: calc(100% + 60px);
  max-width: none;
}

.regional-overview {
  background: #111f90;
}
.regional-overview .icon {
  height: 88px;
}
@media screen and (min-width: 990px) {
  .regional-overview .icon {
    height: 155px;
    margin-left: auto;
  }
}
.regional-overview .icon img {
  margin-left: 15px;
}
@media screen and (min-width: 990px) {
  .regional-overview .icon img {
    width: calc(100% + 100px);
    margin-left: auto;
  }
}
.regional-overview p {
  color: #fff;
}
.regional-overview .btn.btn-hollow {
  color: #111f90;
  border-color: #111f90;
  transition: all 0.5s ease-in-out;
}
.regional-overview .btn.btn-hollow:hover, .regional-overview .btn.btn-hollow:focus {
  color: #262626;
  background-color: #111f90;
}

.map {
  overflow: hidden;
  background: #666;
}
.map img {
  position: relative;
  left: 50%;
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%);
}
.testimonials {
  padding: 50px 0;
}
@media screen and (min-width: 990px) {
  .testimonials {
    padding: 80px;
  }
}
.testimonials .slideshow {
  z-index: 50;
  max-width: 100rem;
  margin: 0 auto;
}
.testimonials .slide {
  position: relative;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  padding: 110px 20px;
}
@media screen and (min-width: 990px) {
  .testimonials .slide {
    flex-wrap: nowrap;
    padding: 80px 80px 120px 150px;
  }
}
.testimonials .slide:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 150px;
  height: 76px;
  background: transparent url(/themes/SSGA/assets/dist/images/testimonial-quote-block_MBL.png) no-repeat top left;
  background-size: contain;
  content: "";
  transform: translateX(-50%);
}
@media screen and (min-width: 990px) {
  .testimonials .slide:before {
    top: 0;
    left: 0;
    height: 150px;
    background-image: url("/themes/SSGA/assets/dist/images/testimonial-quote-block.png");
    transform: none;
  }
}
.testimonials .slide:after {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  display: block;
  width: 150px;
  height: 76px;
  background: transparent url("/themes/SSGA/assets/dist/images/testimonial-quote-block_MBL.png") no-repeat top left;
  background-size: contain;
  content: "";
  transform: translateX(-50%) rotate(180deg);
}
@media screen and (min-width: 990px) {
  .testimonials .slide:after {
    right: 0;
    bottom: 0;
    left: auto;
    height: 150px;
    background-image: url("/themes/SSGA/assets/dist/images/testimonial-quote-block.png");
    transform: rotate(180deg);
  }
}
.testimonials .author {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  position: relative;
  display: inline-block;
  order: 2;
  width: 275px;
  margin-bottom: 55px;
  font-size: 18px;
  line-height: 24px;
  color: #111f90;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (min-width: 990px) {
  .testimonials .author {
    flex-shrink: 2;
    order: 1;
    min-width: 275px;
    padding-right: 20px;
    margin-right: 20px;
    margin-bottom: 0;
    text-align: right;
    border-right: 10px solid #e8e9ef;
  }
  .testimonials .author:after {
    position: absolute;
    top: 0;
    right: -10px;
    width: 10px;
    height: 25px;
    background: #111f90;
    content: "";
  }
}
.testimonials .author .name {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.testimonials .testimonial-text {
  position: relative;
  display: inline-block;
  order: 1;
  width: 100%;
  padding-bottom: 25px;
  margin-top: 50px;
  margin-bottom: 25px;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.testimonials .testimonial-text:after {
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 150px;
  height: 5px;
  background: #dcdeee;
  content: "";
  transform: translateX(-50%);
}
@media screen and (min-width: 990px) {
  .testimonials .testimonial-text {
    flex-grow: 2;
    order: 2;
    width: auto;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }
  .testimonials .testimonial-text:after {
    content: none;
  }
}
.testimonials .testimonial-text p {
  margin: 0;
}

.infographic {
  position: relative;
  padding: 80px 20px 90px;
  background: #222 url("/themes/SSGA/assets/dist/images/statistics-bg.jpg") center top no-repeat;
  background-size: cover;
}
.infographic:before {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 75%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75), transparent);
  content: "";
}
.infographic:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  content: "";
}

.statistics {
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: none;
}
@media screen and (min-width: 990px) {
  .statistics {
    display: flex;
    justify-content: space-between;
    max-width: 1150px;
    margin: 0 auto;
  }
}
.statistics li {
  margin-bottom: 60px;
}
@media screen and (min-width: 990px) {
  .statistics li {
    width: 230px;
    margin-bottom: 0;
  }
}
.statistics li:last-child {
  margin-bottom: 0;
}

.stat-number {
  width: 200px;
  margin: 0 auto 20px;
  font-size: 60px;
  line-height: 200px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 0 5px rgba(0, 8, 51, 0.6);
  background: #cb2128 url("/themes/ssga/assets/dist/images/stat-number-bg.png") no-repeat;
  background-position: 10px calc(100% - 10px);
  background-size: 150px;
  border-radius: 200px;
}

.stat-text {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}
.stat-text strong {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  display: block;
  font-size: 24px;
  line-height: 36px;
}

.key-industries h2 {
  width: 100%;
  margin: 50px 0;
}
@media screen and (min-width: 990px) {
  .key-industries h2 {
    margin-top: 100px;
  }
  .key-industries h2:before, .key-industries h2:after {
    width: calc(50% - 124px);
  }
}
.key-industries .btn.btn-hollow {
  display: block;
  max-width: 290px;
  padding: 0 35px;
  margin: 50px auto;
  text-align: center;
  text-decoration: none;
  background: #fff;
  border-color: #dfdfdf;
  transition: all 0.5s ease-in-out;
}
.key-industries .btn.btn-hollow:hover, .key-industries .btn.btn-hollow:focus {
  background: #262626;
  border-color: #262626;
}
@media screen and (min-width: 990px) {
  .key-industries .btn.btn-hollow {
    margin-bottom: 100px;
  }
}

.industries {
  padding: 0;
  margin: 0;
  list-style: none;
}
@media screen and (min-width: 990px) {
  .industries {
    display: flex;
    justify-content: space-between;
  }
}

.industry {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 2px;
  text-align: center;
  background: #f2f2f2 url("/themes/SSGA/assets/dist/images/key-industries-default.jpg") no-repeat center center;
  background-size: cover;
}
@media screen and (min-width: 990px) {
  .industry {
    height: 420px;
    min-width: 0;
    margin-right: 2px;
    margin-bottom: 0;
    transition: min-width 0.25s ease-in-out;
    transition: all 0.5s ease-in-out;
  }
  .industry:hover, .industry:focus, .industry:focus-within {
    min-width: 540px;
    cursor: pointer;
  }
}
.industry:after {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgb(0, 0, 0), transparent);
  content: "";
}
.industry a {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
}
.industry .industry-title {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  bottom: 40px;
  left: 50%;
  z-index: 1;
  display: inline-block;
  width: calc(100% - 40px);
  padding: 15px 50px;
  font-size: 21px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%);
}
@media screen and (min-width: 990px) {
  .industry .industry-title {
    width: 250px;
    padding: 15px 20px;
    font-size: 18px;
  }
}
.industry .industry-title:before, .industry .industry-title:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 0;
  height: 0;
  border-bottom: 25px solid transparent;
  border-left: 25px solid #111f90;
  content: "";
}
@media screen and (min-width: 990px) and (max-width: 1400px) {
  .industry .industry-title:before, .industry .industry-title:after {
    content: none;
  }
}
.industry .industry-title:after {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  border-bottom: 25px solid #cb2128;
  border-left: 25px solid transparent;
}

.featured-resources {
  padding-top: 50px;
  background: #e7e8f4;
}
.featured-resources h2 {
  margin-bottom: 50px;
}
@media screen and (min-width: 990px) {
  .featured-resources h2 {
    padding: 0;
  }
  .featured-resources h2:before, .featured-resources h2:after {
    width: calc(50% - 184px);
  }
}
.featured-resources:after {
  display: table;
  clear: both;
  content: "";
}

.resources {
  padding: 0;
  margin: 0 0 100px 0;
  list-style: none;
}
@media screen and (min-width: 990px) {
  .resources {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    margin: 90px auto 80px;
  }
}

.resource {
  position: relative;
  padding: 0 20px 30px;
  margin-bottom: 50px;
}
@media screen and (min-width: 990px) {
  .resource {
    width: 33%;
  }
}
.resource:before {
  position: absolute;
  bottom: -10px;
  left: 50%;
  z-index: 1;
  width: 11px;
  height: 26px;
  background: transparent url(/themes/SSGA/assets/dist/images/icn-arrow.png) no-repeat center center;
  content: "";
  transform: translateX(-50%);
}
.resource:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80px;
  height: 5px;
  background: #d1d3ea;
  border-radius: 10px;
  content: "";
  transform: translateX(-50%);
}
.resource img {
  display: block;
  width: 150px;
  height: 150px;
  margin: 0 auto 30px;
}
.resource a {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  display: block;
  display: block;
  width: 100%;
  height: 120%;
  font-size: 28px;
  color: #111f90;
  text-align: center;
  text-decoration: none;
  border: 0;
  transition: all 0.5s ease-in-out;
}
.resource a:hover, .resource a:focus {
  color: #262626;
}

.investors {
  position: relative;
  padding-top: 50px;
  padding-bottom: 130px;
}
.investors.show-all .slideshow {
  max-height: none;
}
@media screen and (min-width: 990px) {
  .investors.show-all .slideshow {
    min-width: 80vw;
    margin: 0 auto;
  }
}
.investors.show-all .mobile-show-all-companies {
  height: 40px;
  background: transparent;
}
.investors.show-all .mobile-show-all-companies:after {
  transform: translateX(-50%) rotate(-90deg);
}
.investors h2 {
  margin-bottom: 80px;
}
@media screen and (min-width: 990px) {
  .investors h2 {
    padding: 0;
  }
  .investors h2:before, .investors h2:after {
    width: calc(50% - 145px);
  }
}
.investors .slideshow {
  max-width: 100%;
  max-height: 390px;
}
@media screen and (min-width: 990px) {
  .investors .slideshow {
    top: 40px;
    width: 100vw !important;
    max-width: none;
    max-height: none;
  }
}
@media screen and (max-width: 989px) {
  .investors .slideshow .slide.cycle-slide.cycle-sentinel {
    display: none !important;
  }
}
@media screen and (max-width: 989px) {
  .investors .slideshow .cycle-carousel-wrap {
    position: static !important;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (min-width: 990px) {
  .investors .slideshow .cycle-carousel-wrap {
    display: block;
    width: auto;
  }
}
.investors .slideshow .slide {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  display: inline-flex !important;
  align-items: center;
  flex-direction: column;
  height: 65px;
  min-width: 140px;
  margin-right: 80px;
  margin-bottom: 40px;
  font-size: 24px;
  text-transform: uppercase;
  vertical-align: middle;
  border: 0;
}
@media screen and (max-width: 989px) {
  .investors .slideshow .slide {
    width: 100% !important;
  }
  .investors .slideshow .slide.mobile-hide {
    display: none !important;
  }
}
@media screen and (min-width: 990px) {
  .investors .slideshow .slide {
    flex-direction: row;
    margin-bottom: 0;
  }
}
.investors .slideshow .slide .company-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.investors .slideshow .slide .company-logo img {
  width: 100%;
  height: 100%;
  max-width: 160px;
  max-height: 40px;
  object-fit: contain;
}
.investors .slideshow .slide .company-name {
  margin-top: 15px;
  color: #848484;
}
@media screen and (min-width: 990px) {
  .investors .slideshow .slide .company-name {
    margin-top: 0;
    margin-right: 6px;
    margin-left: 6px;
  }
}
.investors .pager-controls {
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 0;
  overflow: visible;
}
@media screen and (min-width: 990px) {
  .investors .pager-controls {
    display: block;
  }
}
.investors .pager-controls .company-next,
.investors .pager-controls .company-prev {
  position: absolute;
  top: 50%;
  right: 0;
  width: 233px;
  height: 180px;
  font-size: 3rem;
  line-height: 180px;
  text-align: center;
  text-indent: 60px;
  cursor: pointer;
  background: linear-gradient(to left, white 35%, transparent);
  transform: translateY(-10%);
}
.investors .pager-controls .company-next .carousel-arrow,
.investors .pager-controls .company-prev .carousel-arrow {
  position: relative;
  top: 50%;
  display: block;
  width: 20px;
  height: 38px;
  margin: 0 60px 0 auto;
  background: transparent url(/themes/SSGA/assets/dist/images/icn-carousel-arrow.png);
  background-repeat: no-repeat;
  transform: translateY(-50%);
}
.investors .pager-controls .company-prev {
  right: auto;
  left: 0;
  text-indent: -60px;
  cursor: pointer;
  background: linear-gradient(to right, white 35%, transparent);
}
.investors .pager-controls .company-prev .carousel-arrow {
  margin: 0 auto 0 60px;
  transform: rotate(180deg) translateY(50%);
}
.investors .mobile-show-all-companies {
  position: absolute;
  bottom: 80px;
  z-index: 100;
  width: 100%;
  height: 195px;
  padding-top: 130px;
  font-size: 18px;
  color: #111f90;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(to top, white 45%, rgba(255, 255, 255, 0.0001));
}
@media screen and (min-width: 990px) {
  .investors .mobile-show-all-companies {
    display: none;
  }
}
.investors .mobile-show-all-companies:after {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 40px;
  height: 25px;
  background: transparent url("/themes/SSGA/assets/dist/images/icn-carousel-arrow.png") no-repeat center center;
  background-size: contain;
  content: "";
  transform: translateX(-50%) rotate(90deg);
}

.mid-illinois-medical-district main .three-column {
  background: url(/themes/SSGA/assets/dist/images/Texture.png);
}
.mid-illinois-medical-district main .three-column .col {
  margin: 140px auto;
}
.mid-illinois-medical-district main .three-column .col .hp-icon-mim {
  width: 180px;
  height: 180px;
}

.two-column-hp-text {
  margin-left: 20px;
  margin-right: 20px;
  max-width: 90%;
}

.featured-tiles {
  position: relative;
  left: 50%;
  width: 100vw;
  margin-top: 100px;
  transform: translateX(-50%);
}
@media all and (min-width: 990px) {
  .featured-tiles .tile {
    display: flex;
  }
}
.featured-tiles .tile:nth-child(even) {
  flex-direction: row-reverse;
}
.featured-tiles .tile:nth-child(even) .tile-image:before, .featured-tiles .tile:nth-child(even) .tile-image:after {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  border-color: #cb2128;
}
.featured-tiles .tile:nth-child(even) .tile-image:after {
  top: 0;
  right: 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.featured-tiles .tile:nth-child(even) .tile-image:before {
  bottom: 0;
  left: 0;
  border-top-color: transparent;
  border-right-color: transparent;
}
.featured-tiles .tile-image {
  position: relative;
  display: inline-block;
  margin: 0;
}
@media all and (min-width: 990px) {
  .featured-tiles .tile-image {
    width: 50%;
  }
}
.featured-tiles .tile-image:before, .featured-tiles .tile-image:after {
  position: absolute;
  z-index: 5;
  display: block;
  width: 0;
  height: 0;
  border: 40px solid #111f90;
  content: "";
}
.featured-tiles .tile-image:before {
  top: 0;
  left: 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.featured-tiles .tile-image:after {
  right: 0;
  bottom: 0;
  border-top-color: transparent;
  border-left-color: transparent;
}
.featured-tiles .tile-content {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
  vertical-align: top;
  background: #e8e8f5;
}
@media all and (min-width: 990px) {
  .featured-tiles .tile-content {
    width: calc(50% - 4px);
    padding: 50px 100px;
  }
}
.featured-tiles .tile-content > * {
  margin: 0;
}
.featured-tiles .tile-content h3 {
  margin-bottom: 10px;
  color: #0b1492;
}
.featured-tiles .tile-content p {
  margin-bottom: 10px;
}
.featured-tiles .tile-content a.tile-button.btn.btn-hollow {
  font-size: 16px;
  color: #0b1492;
  border-color: #111f90;
}
.featured-tiles .tile-content a.tile-button.btn.btn-hollow:hover, .featured-tiles .tile-content a.tile-button.btn.btn-hollow:focus {
  color: #fff;
  background: #111f90;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.modal {
  overflow: hidden;
}
.modal .modal-content {
  padding: 31px 60px 40px;
}
.modal .modal-content h1 {
  width: calc(100% + 120px);
  padding: 50px 60px;
  margin: -31px -60px 40px;
  color: #fff;
  text-align: left;
  background: #111f90;
}
.modal .modal-content p {
  text-align: left;
}
.modal .modal-content .close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.5s ease-in-out;
}
.modal .modal-content .close-modal:before {
  position: absolute;
  top: -16px;
  left: -30px;
  z-index: 0;
  width: 90px;
  height: 50px;
  background: #0e3ca0;
  content: "";
  transform: rotate(45deg);
  transition: all 0.5s ease-in-out;
}
.modal .modal-content .close-modal:after {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 13px;
  height: 13px;
  font-size: 20px;
  font-weight: normal;
  background: transparent url(/themes/SSGA/assets/dist/images/icn-close.png) no-repeat center center;
  background-size: contain;
  content: "";
  transition: all 0.5s ease-in-out;
}
.modal .modal-content .close-modal:hover:before, .modal .modal-content .close-modal:focus:before {
  background: #262626;
}

body.nav-wrap-open {
  overflow: hidden !important;
}

.navigation {
  display: none;
  height: calc(100vh - 80px);
  background: #01071d;
}
@media screen and (min-width: 990px) {
  .navigation {
    display: block;
    width: 0;
    height: 0;
    background: transparent;
  }
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .navigation {
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 530px);
    height: 100%;
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
    border-left: 5px solid transparent;
  }
  .stuck .navigation {
    height: auto;
    border: 0;
  }
}
.nav-wrap-open .navigation {
  display: block;
}

@media screen and (min-width: 990px) {
  .nav-primary {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    display: block;
    width: 210px;
    height: 210px;
    padding: 80px;
    background: transparent;
    transition: width 2s ease-in-out, height 2s ease-in-out;
  }
  .nav-wrap-open .nav-primary {
    width: 50%;
    height: 100vh;
    transition: 0s ease-in-out;
  }
  .nav-primary:before {
    position: absolute;
    top: -238px;
    right: -4.8rem;
    width: 650px;
    height: 170px;
    background: #062336;
    content: "";
    transform: skewY(45deg);
    transition: all 2s ease-out;
  }
  .nav-wrap-open .nav-primary:before {
    height: 210vh;
  }
}
@media screen and (min-width: 1280px) {
  .nav-primary {
    position: static;
    order: 2;
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent;
    transition: 0s ease-in-out;
  }
  .nav-primary:before {
    content: none;
  }
  .stuck .nav-primary {
    position: absolute;
    right: -100%;
    width: 50vw;
    height: 100vh;
    max-width: 800px;
    padding: 80px;
    background: #062336;
    transition: 0s ease-in-out;
  }
  .nav-wrap-open .stuck .nav-primary {
    right: 0;
  }
}

.nav-mask {
  position: relative;
  top: 0;
  height: 100%;
  opacity: 0;
  transition: top 0s ease 0.85s;
}
.nav-mask.closed h2 {
  display: none;
}
@media screen and (max-width: 989px) {
  .nav-mask {
    opacity: 1 !important;
  }
}
@media screen and (min-width: 1280px) {
  .nav-mask {
    height: auto;
    opacity: 1 !important;
  }
}
.nav-mask .mask-element {
  position: absolute;
  top: -100%;
  left: 0;
  z-index: 4;
  display: none;
  width: 100%;
  height: 200%;
  background: linear-gradient(0deg, rgb(116, 188, 31) 50%, rgba(116, 188, 31, 0) 100%);
}
@media screen and (min-width: 990px) {
  .nav-mask .mask-element {
    display: block;
  }
}
@media screen and (min-width: 1280px) {
  .nav-mask .mask-element {
    display: none;
  }
}

.ul1 {
  max-height: calc(100vh - 200px);
  padding: 50px 20px 75px;
  margin: 0;
  overflow: auto;
  list-style: none;
  background: #062336;
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .ul1 {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    padding: 0;
    margin-right: 75px;
    overflow: visible;
    background: transparent;
  }
}

.li1 {
  position: relative;
  margin-top: 34px;
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .li1 {
    width: auto;
    padding-right: 18px;
    margin-top: 0;
    margin-right: 18px;
  }
  header:not(.stuck) .li1:last-child {
    margin-right: 0;
  }
  header:not(.stuck) .li1:focus .a1, header:not(.stuck) .li1:hover .a1 {
    color: #cb2128;
  }
  header:not(.stuck) .li1:focus .ul2, header:not(.stuck) .li1:hover .ul2 {
    display: block;
  }
}
@media screen and (min-width: 1400px) {
  header:not(.stuck) .li1 {
    margin-right: 35px;
    padding-right: 25px;
  }
}
.li1:first-child {
  margin-top: 0;
}
.li1 .toggler {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.25rem;
  height: 41px;
  cursor: pointer;
}
@media screen and (min-width: 1280px) {
  .li1 .toggler {
    display: block;
    width: 18px;
    height: 18px;
  }
}
@media screen and (min-width: 1400px) {
  .li1 .toggler {
    width: 25px;
    height: 25px;
  }
}
.li1 .toggler:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-rendering: optimizeLegibility;
  display: block;
  width: 3.25rem;
  height: 41px;
  font-size: 20px;
  color: #cb2128;
  text-align: center;
  border-bottom: 2px solid #cb2128;
  content: "\f067";
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .li1 .toggler:after {
    width: 100%;
    height: auto;
    color: #cb2128;
    border: 0;
    content: "\f107";
  }
}
.li1 .toggler:focus:after, .li1 .toggler:hover:after {
  color: #fff;
  border-bottom-color: #fff;
}
.li1.open .toggler:after {
  content: "\f068";
}

.a1 {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  width: calc(100% - 55px);
  padding-bottom: 18px;
  margin-bottom: 20px;
  font-size: 28px;
  color: #fff !important;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid #cb2128;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 1280px) {
  .a1 {
    padding-bottom: 7px;
    line-height: 32px;
  }
  header:not(.stuck) .a1 {
    width: 100%;
    font-size: 18px;
    letter-spacing: 0.05rem;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    border: 0;
  }
}
@media screen and (min-width: 1380px) {
  header:not(.stuck) .a1 {
    font-size: 22px;
  }
}
.a1:visited {
  color: #262626;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .a1:visited {
    color: #fff;
  }
}
.a1:visited:hover, .a1:visited:focus {
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid #fff !important;
}
.a1:hover, .a1:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid #fff !important;
}

.ul2 {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .ul2 {
    position: absolute;
    right: 0;
    left: auto;
    width: 220px;
    padding: 30px 25px 20px 25px;
    text-align: right;
    background: rgba(0, 0, 0, 0.8);
    border-top: 3px solid #cb2128;
    --notchSize: 20px;
    clip-path: polygon(0% 0, 0 0%, 100% 0%, 100% var(--notchSize), 100% 100%, calc(100% - var(--notchSize)) 100%, var(--notchSize) 100%, 0% calc(100% - var(--notchSize)));
  }
}
.open .ul2 {
  display: block;
}

.li2 {
  margin-bottom: 20px;
}
.li2 .toggler {
  display: none !important;
}

.a2 {
  color: #cb2128 !important;
  text-decoration: none;
  border: 0;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .a2 {
    color: #fff;
  }
}
.a2:visited {
  color: #fff;
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .a2:visited {
    color: #fff;
  }
}
.a2:hover, .a2:focus, .a2:visited:hover, .a2:visited:focus {
  color: #fff !important;
  text-decoration: none;
}
@media screen and (min-width: 1280px) {
  header:not(.stuck) .a2:hover, header:not(.stuck) .a2:focus, header:not(.stuck) .a2:visited:hover, header:not(.stuck) .a2:visited:focus {
    text-decoration: underline;
  }
}

.nav-toggle {
  position: relative;
  float: right;
  width: 60px;
  height: 100%;
  overflow: hidden;
  vertical-align: top;
  cursor: pointer;
  background: #cb2128;
  transition: all 0.5s ease-in-out;
}
.nav-toggle .nav-text {
  display: none;
}
@media screen and (min-width: 1280px) {
  .stuck .nav-toggle .nav-text {
    display: block;
  }
}
@media screen and (min-width: 990px) {
  .nav-toggle {
    width: 110px;
    height: auto;
    padding-top: 10px;
    background: transparent;
  }
  .nav-toggle:before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 0;
    height: 0;
    border-top: 180px solid #062336;
    border-right: 0;
    border-bottom: 0;
    border-left: 180px solid transparent;
    content: "";
    opacity: 1;
    transition: all 0.25s ease-in-out;
  }
}
@media screen and (min-width: 1280px) {
  .nav-toggle {
    display: none;
  }
  .stuck .nav-toggle {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 90px;
    padding-top: 0;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.075rem;
    color: #fff;
    background: #062336;
  }
}
.nav-toggle:hover, .nav-toggle:focus, .nav-wrap-open .nav-toggle {
  background: #111f90;
}
@media screen and (min-width: 990px) {
  .nav-toggle:hover, .nav-toggle:focus, .nav-wrap-open .nav-toggle {
    background: transparent;
  }
  .stuck .nav-toggle:hover, .stuck .nav-toggle:focus, .stuck .nav-wrap-open .nav-toggle {
    background: #062336;
  }
}
.nav-toggle .fa-bars,
.nav-toggle .fa-times {
  font-size: 25px;
  line-height: 80px;
  color: #fff;
  text-align: center;
}
@media screen and (min-width: 990px) {
  .nav-toggle .fa-bars,
.nav-toggle .fa-times {
    font-size: 46px;
  }
}
@media screen and (min-width: 1280px) {
  .stuck .nav-toggle .fa-bars,
.stuck .nav-toggle .fa-times {
    margin-right: 6px;
    font-size: 24px;
  }
}
.nav-toggle .fa-bars {
  display: block;
}
.nav-wrap-open .nav-toggle .fa-bars {
  display: none;
}
.nav-toggle .fa-times {
  display: none;
}
.nav-wrap-open .nav-toggle .fa-times {
  display: block;
}

header {
  background-color: #062336;
}
header.stuck {
  background-color: #062336;
}

.global-nav {
  padding: 0;
  margin: 30px 20px;
  list-style: none;
}
@media screen and (min-width: 990px) {
  .global-nav {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    padding: 30px 20px;
    padding-right: 200px;
    margin: 0;
    text-align: right;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
  }
}
@media screen and (min-width: 1280px) {
  .global-nav {
    position: static;
    order: 1;
    width: 100%;
    padding: 0;
    margin: 32px 75px 32px 0;
    background: transparent;
  }
  .stuck .global-nav {
    position: absolute;
    top: 0;
    right: 120px;
    height: 90px;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 0;
    line-height: 90px;
  }
}
.global-nav li {
  display: inline-block;
  margin-right: 20px;
}
.global-nav li:last-child {
  margin-right: 0;
}
.global-nav li a {
  position: relative;
  padding-left: 25px;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.025rem;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  transition: all 0.5s ease-in-out;
}
.stuck .global-nav li a {
  color: #fff;
}
.global-nav li a:before {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 900;
  color: #cb2128 !important;
  content: "\f00d";
}
.global-nav li a.Phone:before {
  content: "\f095";
}
.global-nav li a.Email:before {
  content: "\f0e0";
}
.global-nav li a.Web:before {
  content: "\f0ac";
}
.global-nav li a:hover, .global-nav li a:focus {
  color: #cb2128;
  text-decoration: underline;
}

.article-listing-container .filter-buttons {
  margin-bottom: 40px;
}
.article-listing-container:after {
  content: "";
  display: table;
  width: 100%;
  height: 50px;
}

article.news-post {
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 2px solid #dcdeee;
}
article.news-post:first-of-type {
  display: none;
}
article.news-post:last-of-type {
  margin-bottom: 0;
  border-bottom: 0;
}
article.news-post:after {
  display: table;
  clear: both;
  content: "";
}
@media screen and (min-width: 990px) {
  article.news-post > * {
    float: right;
    width: calc(100% - 280px);
  }
}
article.news-post h2.news-post-headline {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 30px;
  color: #111f90;
}
article.news-post h2.news-post-headline a {
  font-size: 30px;
  line-height: 30px;
  border: 0;
}
article.news-post .news-post-date {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #262626;
}
article.news-post .news-post-read-more a {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  line-height: 40px;
  color: #111f90;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  border: 2px solid #111f90;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 990px) {
  article.news-post .news-post-read-more a {
    width: auto;
  }
}
article.news-post .news-post-read-more a:hover, article.news-post .news-post-read-more a:focus {
  color: #fff;
  background: #111f90;
}
article.news-post .news-post-image {
  position: relative;
  width: 100%;
  height: 185px;
  margin-bottom: 20px;
  overflow: hidden;
  --notchSize: 35px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
@media all and (min-width: 450px) and (max-width: 520px) {
  article.news-post .news-post-image {
    height: 295px;
  }
}
@media all and (min-width: 521px) and (max-width: 640px) {
  article.news-post .news-post-image {
    height: 335px;
  }
}
@media all and (min-width: 641px) and (max-width: 940px) {
  article.news-post .news-post-image {
    height: 425px;
  }
}
@media all and (min-width: 941px) and (max-width: 989px) {
  article.news-post .news-post-image {
    height: 475px;
  }
}
@media screen and (min-width: 990px) {
  article.news-post .news-post-image {
    float: left;
    width: 250px;
    margin-right: 30px;
  }
}
article.news-post .news-post-image img {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
}

.news-search {
  display: none;
}

.news-detail {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  border-bottom: 2px solid #dcdeee;
}
.news-detail > * {
  width: 100%;
}
.news-detail .news-detail-headline {
  order: 1;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 30px;
}
.news-detail .news-detail-date {
  position: relative;
  order: 2;
  padding-bottom: 20px;
  padding-left: 40px;
  font-size: 20px;
  color: #262626;
  border-bottom: 2px solid #dcdeee;
}
.news-detail .news-detail-date:before {
  position: absolute;
  top: -2px;
  left: 0;
  width: 30px;
  height: 26px;
  background: transparent url("/themes/SSGA/assets/dist/images/icn-calendar.png") no-repeat center center;
  background-size: contain;
  content: "";
}
.news-detail .news-detail-image {
  order: 3;
  width: 100%;
  height: auto;
  margin: 20px 0;
}
.news-detail .news-detail-image img {
  width: 100%;
  --notchSize: 40px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
.news-detail .news-detail-copy {
  order: 4;
}
.news-detail .news-detail-attachments {
  order: 5;
}
.news-detail .news-detail-link {
  display: none;
}

.pager-controls {
  position: relative;
  top: -141px;
  left: 50%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
}
.banner .pager-controls {
  position: absolute;
  top: auto;
  bottom: 45px;
  left: 49.5%;
  z-index: 999;
  transform: translateX(-50%);
}
@media screen and (min-width: 990px) {
  .banner .pager-controls {
    right: -65px;
    bottom: 45px;
    left: auto;
  }
}
@media screen and (min-width: 1440px) {
  .banner .pager-controls {
    right: 70px;
  }
}
@media screen and (min-width: 990px) {
  .pager-controls {
    top: -71px;
    left: 25.1875rem;
    justify-content: left;
    transform: none;
  }
}

.test-next,
.test-prev,
.banner-next,
.banner-prev {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  font-size: 18px;
  line-height: 25px;
  color: #111f90;
  text-align: center;
  cursor: pointer;
  background: #e7e8f4;
  border-radius: 25px;
}
.banner .test-next,
.banner .test-prev,
.banner .banner-next,
.banner .banner-prev {
  background: rgba(255, 255, 255, 0.2);
}
.test-next .fas,
.test-prev .fas,
.banner-next .fas,
.banner-prev .fas {
  display: inline-block;
  width: 25px;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
}
.test-next .fas.fa-chevron-right,
.test-prev .fas.fa-chevron-right,
.banner-next .fas.fa-chevron-right,
.banner-prev .fas.fa-chevron-right {
  padding-left: 2px;
}
.test-next .fas.fa-chevron-left,
.test-prev .fas.fa-chevron-left,
.banner-next .fas.fa-chevron-left,
.banner-prev .fas.fa-chevron-left {
  padding-right: 2px;
}
.test-next.disabled,
.test-prev.disabled,
.banner-next.disabled,
.banner-prev.disabled {
  color: #777;
  cursor: default;
}
.test-next.disabled .fas,
.test-prev.disabled .fas,
.banner-next.disabled .fas,
.banner-prev.disabled .fas {
  cursor: default;
}

.test-pager,
.banner-pager {
  position: relative;
  top: -2px;
  font-size: 48px;
  line-height: 18px;
}
.test-pager > span,
.banner-pager > span {
  margin-right: 5px;
  color: #dcdeee;
  cursor: pointer;
}
.banner .test-pager > span,
.banner .banner-pager > span {
  color: rgba(255, 255, 255, 0.2);
}
.test-pager > span.cycle-pager-active,
.banner-pager > span.cycle-pager-active {
  color: #111f90;
}

.subpage .banner-wrapper {
  position: relative;
  height: 120px;
  margin-top: 80px;
  overflow: hidden;
  background: #262626 url("/themes/SSGA/assets/dist/images/subpage-banner-image-default.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 990px) {
  .subpage .banner-wrapper {
    height: 365px;
    margin-top: 0;
  }
}
.subpage .banner-wrapper h1 {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 40px;
  margin: 0;
  font-size: 36px;
  letter-spacing: 0.05rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50%) translateY(-50%);
}
@media screen and (min-width: 990px) {
  .subpage .banner-wrapper h1 {
    top: auto;
    right: 0;
    bottom: 64px;
    left: auto;
    z-index: 2;
    padding-right: 80px;
    padding-left: 400px;
    font-size: 48px;
    line-height: 48px;
    text-align: right;
    transform: none;
  }
}
.subpage .banner-wrapper p {
  display: flex;
  height: 100%;
  margin: 0;
  object-fit: cover;
}
.subpage .banner-wrapper img {
  position: relative;
  left: 50%;
  width: 100%;
  height: auto;
  min-height: 120px;
  transform: translateX(-50%);
  transition: all 0.25s ease-in-out;
}

@media (min-width: 1280px) {
  :not(.calendar-table) table {
    position: relative;
    margin-bottom: 40px;
    border-collapse: separate;
  }
  :not(.calendar-table) table thead {
    color: white;
    white-space: nowrap;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    font-size: 16px;
    line-height: 21px;
    font-size: 1rem;
    line-height: 1.3125rem;
    letter-spacing: normal;
  }
  :not(.calendar-table) table thead th {
    padding: 10px 20px;
    text-align: left;
  }
  :not(.calendar-table) table thead th:first-child {
    border-top-left-radius: 6px;
  }
  :not(.calendar-table) table thead th:last-child {
    border-right: 0;
    border-top-right-radius: 6px;
  }
  :not(.calendar-table) table tbody {
    font-size: 16px;
    line-height: 21px;
    font-size: 1rem;
    line-height: 1.3125rem;
    letter-spacing: normal;
  }
  :not(.calendar-table) table tbody tr:nth-child(odd) {
    background: white;
  }
  :not(.calendar-table) table tbody tr td {
    position: relative;
    padding: 10px 20px;
    word-wrap: break-word;
  }
  :not(.calendar-table) table tbody tr td:first-child {
    padding-left: 22px;
    border-left: 0;
  }
  :not(.calendar-table) table tbody tr td:first-child:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    content: "";
  }
  :not(.calendar-table) table tbody tr td:last-child {
    padding-right: 22px;
    border-right: 0;
  }
  :not(.calendar-table) table tbody tr td:last-child:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    content: "";
  }
}
@media screen and (max-width: 1279px) {
  :not(.calendar-table) table.responsive-table {
    font-size: 16px;
    line-height: 21px;
    font-size: 1rem;
    line-height: 1.3125rem;
    letter-spacing: normal;
  }
  :not(.calendar-table) table.responsive-table tbody tr:nth-child(even) {
    background: white;
  }
  :not(.calendar-table) table.responsive-table tbody tr:nth-child(even) td:first-child:before,
:not(.calendar-table) table.responsive-table tbody tr:nth-child(even) td:last-child:after {
    display: block;
  }
  :not(.calendar-table) table.responsive-table tbody tr td {
    position: relative;
    padding: 0;
  }
  :not(.calendar-table) table.responsive-table .cell-heading,
:not(.calendar-table) table.responsive-table .cell-content {
    padding: 5px 10px;
  }
  :not(.calendar-table) table.responsive-table .cell-heading {
    font-size: 16px;
    line-height: 21px;
    font-size: 1rem;
    line-height: 1.3125rem;
    letter-spacing: normal;
  }
}

.tabs .tab-con {
  border-bottom: 2px solid #e7e8f4;
}
.tabs .tab-list {
  margin-bottom: -2px;
}
.tabs .tab-list li {
  margin-right: 5px;
}
.tabs .tab-list li:before {
  content: none;
}
.tabs .tab-list li a {
  padding: 20px 40px;
  font-size: 18px;
  line-height: 18px;
  color: #111f90;
  text-transform: none;
  background: #e7e8f4;
  border-bottom: 2px solid #d7d9ed;
  transition: all 0.5s ease-in-out;
  --notchSize: 20px;
  clip-path: polygon(calc(100% - var(--notchSize)) 0, 100% var(--notchSize), 100% 100%, 0 100%, 0 0);
}
.tabs .tab-list li a[aria-selected=true] {
  padding: 20px 40px;
  color: #fff;
  background-color: #111f90;
  border-bottom: 2px solid #111f90;
  transition: all 0.5s ease-in-out;
  clip-path: none;
}
.tabs .tab-list li a[aria-selected=true]:focus {
  color: #fff;
  background-color: #111f90;
  border-bottom: 2px solid #111f90;
}
.tabs .tab-list li a[aria-selected=true]:hover, .tabs .tab-list li a[aria-selected=true]:focus {
  color: #111f90;
  background: #e7e8f4;
  border-bottom: 2px solid #111f90;
}
.tabs .tab-list li a:hover, .tabs .tab-list li a:focus {
  color: #111f90;
  background: #e7e8f4;
  border-bottom: 2px solid #111f90;
}

h2,
h3,
h4,
h5,
h6 {
  margin: 50px 0 20px;
}
h2 + h2,
h2 + h3,
h2 + h4,
h2 + h5,
h2 + h6,
h3 + h2,
h3 + h3,
h3 + h4,
h3 + h5,
h3 + h6,
h4 + h2,
h4 + h3,
h4 + h4,
h4 + h5,
h4 + h6,
h5 + h2,
h5 + h3,
h5 + h4,
h5 + h5,
h5 + h6,
h6 + h2,
h6 + h3,
h6 + h4,
h6 + h5,
h6 + h6 {
  margin-top: 0;
}

h2 {
  font-size: 36px;
  line-height: 30px;
  color: #cb2128;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}
h2.small {
  font-size: 30px;
}

h3 {
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 30px;
  color: #262626;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  line-height: 30px;
  color: #262626;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}

h5 {
  font-size: 18px;
  line-height: 30px;
  color: #262626;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}

h6 {
  font-size: 20px;
  font-style: italic;
  line-height: 30px;
  color: #262626;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}

p,
ul,
ol {
  margin: 15px 0;
  font-size: 18px;
  line-height: 30px;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
}
p.large,
ul.large,
ol.large {
  font-size: 24px;
  line-height: 36px;
}

hr {
  height: 2px;
  margin: 50px 0;
  background: #dcdeee;
  border: 0;
}

a {
  display: inline;
  line-height: 20px;
  color: #111f90;
  text-decoration: none;
  border-bottom: 2px solid #cfd2e9;
  transition: all 0.5s ease-in-out;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}
.large a {
  line-height: 32px;
}
a:visited {
  color: #111f90;
  transition: all 0.5s ease-in-out;
}
a:visited:hover, a:visited:focus {
  color: #262626;
  text-decoration: none;
  border-bottom-color: #262626;
}
a:hover, a:focus {
  color: #262626;
  text-decoration: none;
  border-bottom-color: #262626;
}

strong {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}

blockquote {
  position: relative;
  width: 100%;
  padding: 40px 60px;
  margin: 30px 0;
  overflow: hidden;
  font-size: 24px;
  line-height: 36px;
  background: #e7e8f4;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
}
blockquote:before {
  position: absolute;
  top: -25px;
  left: -25px;
  width: 50px;
  height: 50px;
  background: #53a7dc;
  border: 3px solid #fff;
  content: "";
  transform: rotate(45deg);
}
blockquote:after {
  position: absolute;
  right: -25px;
  bottom: -25px;
  width: 50px;
  height: 50px;
  background: #53a7dc;
  border: 3px solid #fff;
  content: "";
  transform: rotate(45deg);
}
blockquote span {
  font-style: italic;
}

table.responsive-table {
  width: 100%;
  border-collapse: collapse;
}
table.responsive-table th,
table.responsive-table td {
  padding: 20px 30px;
  font-size: 16px;
  text-align: left;
  background: #fff;
  border-bottom: 2px solid #edeef7;
}
table.responsive-table th {
  font-size: 18px;
  color: #fff;
  background: #111f90;
  border-right: 2px solid #edeef7;
  border-bottom: 0;
}
@media screen and (min-width: 990px) {
  table.responsive-table tr:last-child td {
    border-bottom: 0;
  }
}

.main-content img {
  --notchSize: 20px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
.main-content img.no-clip {
  clip-path: none;
}
.main-content .commission-img {
  width: 150px;
  height: auto;
}
.main-content figure figcaption {
  padding: 15px 0;
  font-size: 14px;
  font-style: italic;
  color: #262626;
  background: transparent;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
}
.main-content ul {
  padding: 0 15px;
}
.main-content ul li {
  position: relative;
  display: block;
  padding: 0;
  margin: 0 0 16px 1.1225em;
  color: #262626;
  list-style: none;
}
.main-content ul li:before {
  position: absolute;
  top: -1px;
  left: -16px;
  display: inline-block;
  font-size: 24px;
  color: #111f90;
  content: "•";
}
.main-content ul li li:before {
  font-size: 16px;
  color: #bfc0c0;
}
.main-content ol {
  padding: 0 15px;
  counter-reset: count;
}
.main-content ol > li {
  position: relative;
  display: block;
  padding: 0;
  margin: 0 0 16px 1.1225em;
  color: #262626;
  list-style: none;
}
.main-content ol > li:before {
  position: absolute;
  top: 1px;
  left: -30px;
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  color: #fff;
  text-align: center;
  background: #111f90;
  content: counter(count);
  counter-increment: count;
}
.main-content ol > li li:before {
  font-size: 16px;
  color: #bfc0c0;
  background: transparent;
  content: "•";
}

.btn {
  padding: 0 20px;
  font-size: 18px;
  line-height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  -webkit-appearance: none;
}
.btn.btn-hollow {
  color: #262626;
  background: transparent;
  border: 2px solid #262626;
  transition: all 0.5s ease-in-out;
}
.btn.btn-hollow:hover, .btn.btn-hollow:focus {
  color: #fff;
  background: #262626;
}
.btn.btn-arrow {
  position: relative;
  padding: 0 0 0 20px;
  font-size: 18px;
  color: #262626;
  border: 0;
}
.btn.btn-arrow.btn-back:before {
  position: absolute;
  top: 49%;
  left: 0;
  width: 15px;
  height: 18px;
  background: transparent url("/themes/SSGA/assets/dist/images/icn-arrow.png") no-repeat center center;
  content: "";
  transform: rotate(180deg) translateY(50%);
}

span[id*=DataPager] {
  display: block;
  width: 100%;
  padding-top: 10px;
  color: #111f90;
  text-align: center;
  border-top: 2px solid #edeef7;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}
span[id*=DataPager] a {
  color: #111f90;
  border: 0;
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
}

dl {
  padding: 0;
  margin: 0;
}
dl dt {
  margin-top: 40px;
  font-weight: bold;
}
dl dd {
  max-height: 450px;
  min-height: 40px;
  padding: 10px 15px;
  margin: 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 15px;
}

.posted-by {
  margin-top: -10px;
  margin-bottom: 20px;
  font-style: italic;
}

.two-column .red-header-hp {
  color: #cb2128;
}

.commission-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: auto;
}
@media all and (max-width: 640px) {
  .commission-row {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.commission-img-container {
  margin-right: 20px;
  width: 100%;
  min-width: 160px;
  max-width: 160px;
}
@media all and (max-width: 640px) {
  .commission-img-container {
    margin-bottom: 40px;
  }
}

.commission-header {
  margin: 0 auto;
  color: #cb2128;
  font-size: 30px;
}

.commission-body {
  margin: 0 auto;
}

hr.no-margin {
  margin: 0 0 60px 0;
}

.main-content ul.call-out-nav.mim li a:before {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 1;
  width: 80px;
  height: 26px;
  background: transparent url(/themes/SSGA/assets/dist/images/mimd-callout-arrow.png) no-repeat center center;
  background-size: contain;
  content: "";
  transform: translateX(-50%);
}

body.district-partners.mid-illinois-medical-district .five-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media all and (min-width: 990px) {
  body.district-partners.mid-illinois-medical-district .five-column {
    justify-content: space-between;
  }
}
@media all and (min-width: 1280px) {
  body.district-partners.mid-illinois-medical-district .five-column {
    justify-content: flex-start;
  }
}
body.district-partners.mid-illinois-medical-district .five-column .col {
  min-width: 30%;
  max-width: 690px;
}
body.district-partners.mid-illinois-medical-district .five-column .col .anchor-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media all and (min-width: 990px) {
  body.district-partners.mid-illinois-medical-district .five-column .col .anchor-wrap {
    border: none;
  }
}
body.district-partners.mid-illinois-medical-district .five-column .col .anchor-wrap .image-width-control {
  width: 100%;
  max-width: 690px;
  height: auto;
}
body.district-partners.mid-illinois-medical-district .five-column .col .anchor-wrap p {
  max-width: 690px;
}
body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+1) {
  margin-bottom: 25px;
}
@media all and (min-width: 990px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+1) {
    margin-left: 0;
    margin-right: 15px;
  }
}
@media all and (min-width: 1280px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+1) {
    margin-left: 0;
    margin-right: 30px;
  }
}
body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+2) {
  margin-bottom: 25px;
}
@media all and (min-width: 990px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+2) {
    margin-left: 0;
    margin-right: 15px;
  }
}
@media all and (min-width: 1280px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+2) {
    margin-left: 0;
    margin-right: 30px;
  }
}
body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+3) {
  margin-bottom: 25px;
}
@media all and (min-width: 990px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+3) {
    margin-left: 0;
    margin-right: 15px;
  }
}
@media all and (min-width: 1280px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+3) {
    margin-left: 0;
    margin-right: 30px;
  }
}
body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+4) {
  margin-bottom: 25px;
}
@media all and (min-width: 990px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+4) {
    margin-left: 0;
    margin-right: 15px;
  }
}
@media all and (min-width: 1280px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+4) {
    margin-left: 0;
    margin-right: 30px;
  }
}
body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+5) {
  margin-bottom: 25px;
}
@media all and (min-width: 990px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+5) {
    margin-left: 0;
    margin-right: 15px;
  }
}
@media all and (min-width: 1280px) {
  body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+5) {
    margin-left: 0;
    margin-right: 30px;
  }
}
body.district-partners.mid-illinois-medical-district .five-column .col:nth-of-type(5n+5) .anchor-wrap {
  border: none;
}

.article-listing-container .filter-buttons {
  margin-bottom: 40px;
}
.article-listing-container:after {
  content: "";
  display: table;
  width: 100%;
  height: 50px;
}

article.news-post {
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 2px solid #dcdeee;
}
article.news-post:first-of-type {
  display: none;
}
article.news-post:last-of-type {
  margin-bottom: 0;
  border-bottom: 0;
}
article.news-post:after {
  display: table;
  clear: both;
  content: "";
}
@media screen and (min-width: 990px) {
  article.news-post > * {
    float: right;
    width: calc(100% - 280px);
  }
}
article.news-post h2.news-post-headline {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 30px;
  color: #111f90;
}
article.news-post h2.news-post-headline a {
  font-size: 30px;
  line-height: 30px;
  border: 0;
}
article.news-post .news-post-date {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #262626;
}
article.news-post .news-post-read-more a {
  font-family: source-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  line-height: 40px;
  color: #111f90;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  border: 2px solid #111f90;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}
@media screen and (min-width: 990px) {
  article.news-post .news-post-read-more a {
    width: auto;
  }
}
article.news-post .news-post-read-more a:hover, article.news-post .news-post-read-more a:focus {
  color: #fff;
  background: #111f90;
}
article.news-post .news-post-image {
  position: relative;
  width: 100%;
  height: 185px;
  margin-bottom: 20px;
  overflow: hidden;
  --notchSize: 35px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
@media all and (min-width: 450px) and (max-width: 520px) {
  article.news-post .news-post-image {
    height: 295px;
  }
}
@media all and (min-width: 521px) and (max-width: 640px) {
  article.news-post .news-post-image {
    height: 335px;
  }
}
@media all and (min-width: 641px) and (max-width: 940px) {
  article.news-post .news-post-image {
    height: 425px;
  }
}
@media all and (min-width: 941px) and (max-width: 989px) {
  article.news-post .news-post-image {
    height: 475px;
  }
}
@media screen and (min-width: 990px) {
  article.news-post .news-post-image {
    float: left;
    width: 250px;
    margin-right: 30px;
  }
}
article.news-post .news-post-image img {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(-50%) translateY(-50%);
}

.news-search {
  display: none;
}

.news-detail {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  border-bottom: 2px solid #dcdeee;
}
.news-detail > * {
  width: 100%;
}
.news-detail .news-detail-headline {
  order: 1;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 30px;
}
.news-detail .news-detail-date {
  position: relative;
  order: 2;
  padding-bottom: 20px;
  padding-left: 40px;
  font-size: 20px;
  color: #262626;
  border-bottom: 2px solid #dcdeee;
}
.news-detail .news-detail-date:before {
  position: absolute;
  top: -2px;
  left: 0;
  width: 30px;
  height: 26px;
  background: transparent url("/themes/SSGA/assets/dist/images/icn-calendar.png") no-repeat center center;
  background-size: contain;
  content: "";
}
.news-detail .news-detail-image {
  order: 3;
  width: 100%;
  height: auto;
  margin: 20px 0;
}
.news-detail .news-detail-image img {
  width: 100%;
  --notchSize: 40px;
  clip-path: polygon(100% 0, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0 100%, 0 0);
}
.news-detail .news-detail-copy {
  order: 4;
}
.news-detail .news-detail-attachments {
  order: 5;
}
.news-detail .news-detail-link {
  display: none;
}

.neighborhood-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 70px;
}
@media all and (min-width: 1280px) {
  .neighborhood-row {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}

.neighborhood-img-container {
  margin-right: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 40px;
}
@media all and (min-width: 1280px) {
  .neighborhood-img-container {
    margin-bottom: 0;
    min-width: 50%;
    max-width: 50%;
    margin-right: 20px;
  }
}
.neighborhood-img-container img {
  min-width: 100%;
}

.neighborhood-text-container {
  max-width: 100%;
  min-width: 100%;
}
@media all and (min-width: 1280px) {
  .neighborhood-text-container {
    min-width: 50%;
    max-width: 50%;
  }
}

.neighborhood-header {
  margin: 0 auto;
  color: #cb2128;
  font-size: 30px;
}

.neighborhood-body {
  margin: 0 auto;
}

hr.no-margin {
  margin: 0 0 60px 0;
}

.interactive-map {
  position: relative;
  width: 100%;
  border: 2px solid #d6d8da;
  border-radius: 10px;
}

.map-panel {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 0.33s ease-in-out;
}
.map-panel.active {
  opacity: 1;
}
.map-panel:first-child {
  position: static;
  display: block;
}

.map-legend {
  display: flex;
  appearance: none;
  border: 0;
  background: #f2f2f2;
  border-radius: 10px;
  padding: 15px;
  flex-direction: column;
  margin: 15px 0;
}
@media all and (min-width: 990px) {
  .map-legend {
    flex-direction: row;
  }
}
.map-legend legend {
  margin: -15px -15px 20px -15px;
  width: calc(100% + 30px);
  max-width: none;
  background: #dadada;
  line-height: 55px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 16px;
  float: left;
}
@media all and (min-width: 990px) {
  .map-legend legend {
    margin: -15px 20px -15px -15px;
    width: auto;
  }
}
.map-legend > div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media all and (min-width: 990px) {
  .map-legend > div {
    margin-bottom: 0;
    margin-right: 40px;
  }
}
.map-legend input[type=radio] {
  width: 27px;
  height: 26px;
  border: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.map-legend input[type=radio] + label {
  font-size: 16px;
  top: 0;
  font-weight: bold;
  cursor: pointer;
}
.map-legend input[type=radio]:checked:before {
  content: "";
  width: 14px;
  height: 14px;
  background: #d31c18;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}